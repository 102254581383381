import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FC } from 'react';
import tw, { css } from 'twin.macro';
import {
  Asterisk,
  ContentSection,
  DesktopOnly,
  Footnotes,
  H2,
  H3,
  Hero,
  HR,
  Layout,
  LI,
  MobileOnly,
  NERLYNX,
  NoBreak,
  P,
  References,
  SEO,
  UL,
} from '~/components';
import { Frontmatter } from '~/config';
import heroBackground from '~/images/hero-moa.jpg';
import CovalentBondingSVG from '~/images/moa/covalent-bonding.svg';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Mechanism',
  mobileNavTitle: 'Mechanism of Action',
  desktopNavTitle: 'Mechanism\\nof Action',
  order: 5,
  screenShoot: true,
  anchors: [
    {
      id: 'pan-her-signaling-inhibition',
      label: 'PAN-HER SIGNALING INHIBITION',
      ariaLabel: 'PAN-HER Signaling Inhibition',
    },
    {
      id: 'her2-er-crosstalk',
      label: 'HER2-ER CROSSTALK',
      ariaLabel: 'HER2-ER Crosstalk',
    },
  ],
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout anchors={frontmatter.anchors}>
      <SEO
        title="Mechanism of action | NERLYNX® (neratinib) tablets"
        description="Learn about the mechanism of action for NERLYNX."
        path={props.location?.pathname}
      />
      <Hero
        backgroundImage={heroBackground}
        headlinePadding="right"
        backgroundStyle={tw`background-position[85%] md:(bg-cover)`}
        titleStyle={tw`mb-24 -mt-4 md:(my-8) lg:(my-0)`}
      >
        <NERLYNX /> is the first and only HER2-directed small molecule approved
        in both early-stage and metastatic HER2+ breast cancer<sup>1</sup>
      </Hero>
      <section id="pan-her-signaling-inhibition">
        <ContentSection flavor="light">
          <H2 cyan>
            <NERLYNX /> is a small molecule that demonstrates irreversible
            intracellular <NoBreak>pan-HER</NoBreak> signaling inhibition
            <sup>2</sup>
          </H2>
          <DesktopOnly>
            <CovalentBondingSVG
              tw="my-12"
              alt="Mechanism of action diagram"
              aria-label="Diagram showing the process of covalent bonding in relation to neratinib molecules"
            />
          </DesktopOnly>
          <MobileOnly>
            <StaticImage
              src="../images/moa/covalent-bonding-mobile.png"
              alt="Mechanism of action diagram"
              aria-label="Diagram showing the process of covalent bonding in relation to neratinib molecules"
              tw="my-6"
            />
          </MobileOnly>
          <H3 blue>PAN-HER RECEPTOR TARGETING WITH NERATINIB</H3>
          <UL yellow tw="mb-4">
            <LI gray>
              Irreversibly inhibits HER1 (EGFR), HER2, and HER4 signaling
              <sup>2</sup>
            </LI>
            <LI gray>
              Also inhibits active HER3 heterodimer signaling by interfering
              with downstream signaling<sup>2</sup>
            </LI>
          </UL>
          <H3 blue>ANTI-TUMOR ACTIVITY OF NERATINIB</H3>
          <UL yellow>
            <LI gray>
              Intracellular binding to the receptor tyrosine kinase signaling
              domain leads to sustained inhibition of signaling, inducing cell
              cycle arrest and apoptosis<sup>2</sup>
            </LI>
            <LI gray>
              Inhibition of downstream signaling reduces tumor cell growth and
              proliferation and induces tumor cell death in vitro<sup>2</sup>
            </LI>
          </UL>
          <H3 blue>NERATINIB AND THE BLOOD-BRAIN BARRIER</H3>
          <UL yellow customCss={tw`mb-3`}>
            <LI gray>
              Neratinib passed through a cellular model of the blood-brain
              barrier, reducing the growth of HER2+ breast cancer cells
              <sup>2,</sup>
              <Asterisk />
              <sup>,†</sup>
            </LI>
          </UL>
          <Footnotes
            footnotes={['in-vitro', 'impact-bb']}
            references={[2, 2]}
            abreviations={['EGFR', 'MAPK', 'PI3K', 'RAS']}
            customCss={[
              tw`pb-8 font-bold text-brand-blue md:(pb-16)`,
              css`
                li:last-of-type {
                  font-weight: normal;
                  color: rgb(var(--brand-risky-gray));
                  margin-top: 1rem;
                }
              `,
            ]}
          />
        </ContentSection>
      </section>
      <section id="her2-er-crosstalk">
        <ContentSection flavor="dark" customCss={tw`py-12`}>
          <H2 cyan tw="mb-4">
            <NERLYNX /> impact on HER2-ER crosstalk<sup>2,</sup>
            <Asterisk />
          </H2>
          <H3 white tw="mb-8 lg:(mb-10)">
            HER2 blockade helps inhibit her2-er crosstalk in her2+ er+ disease
            <sup>2,†</sup>
          </H3>
          <article tw="flex flex-col gap-8 mb-4 mt-12 sm:flex-row">
            <div tw="flex-1">
              <StaticImage
                objectFit="contain"
                src="../images/moa/her2-escape-mechanism.png"
                alt="Cell signaling diagram"
                aria-label="Cell diagram illustrating the process of HER signaling without HER2 inhibition"
                width={640}
                tw="mb-4"
              />
              <H3 tw="text-left sm:text-center" white>
                A HER2 escape mechanism
              </H3>
              <P tw="my-4 text-left text-sm sm:text-center">
                When ER is inhibited with endocrine therapy, crosstalk may cause
                upregulation of the HER2 pathway<sup>2</sup>
              </P>
            </div>
            <div tw="flex-1">
              <StaticImage
                objectFit="contain"
                src="../images/moa/dual-blockade.png"
                alt="Crosstalk blockage diagram"
                aria-label="Cell diagram illustrating the process of targeting both HER and ER signaling with neratinib to inhibit crosstalk"
                width={640}
                tw="mb-4"
              />
              <H3 tw="text-left sm:text-center" white>
                Dual Blockade
              </H3>
              <P tw="my-4 text-left text-sm sm:text-center">
                Simultaneous HER2 and ER blockade may inhibit this key mechanism
                of resistance<sup>2,†</sup>
              </P>
            </div>
          </article>
          <Footnotes
            footnotes={['preclinical-model', 'impact']}
            references={[2, 2]}
            abreviations="ER"
            alignment="left"
            customCss={[
              tw`py-4 text-xs font-bold`,
              css`
                li:last-of-type {
                  font-weight: normal;
                  margin-top: 0.5rem;
                }
              `,
            ]}
          />
        </ContentSection>
      </section>
      <HR yellow />
      <ContentSection>
        <References
          references={['package insert', 'puma biotechnology']}
          customCss={tw`py-8`}
        />
      </ContentSection>
    </Layout>
  );
};

export default Page;
